/* ==========================================================================
   #TOP NAV
   ========================================================================== */

$topnav-breapoint: 1032px;

.c-topnav {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;

    @include mq(1032) {
        display: block;
        list-style: none;
        font-size: 0;
    }
}

.c-topnav__item {
    width: 33.33%;
    display: table-cell;
    vertical-align: top;
    @include font-size($inuit-global-font-size);

    + .c-topnav__item {
        border-left: 1px solid white;
    }

    .dropdown-menu {
        z-index: 2;
    }

    @include mq($topnav-breapoint) {
        display: inline-block;
        vertical-align: middle;
        width: auto;
        margin-left: $spacing-unit-small;
        margin-right: $spacing-unit-small;
    }
}

.c-topnav__btn {
    border-radius: 0;
    width: 100%;
    vertical-align: top;
    padding: $spacing-unit-small $spacing-unit-tiny;

    .c-btn__icon {
        display: none;
    }

    @include mq($topnav-breapoint) {
        border-radius: $button-radius;
        padding: $spacing-unit-small $spacing-unit;

        .c-btn__icon {
            display: inline-block;
        }
    }
}

.c-topnav__btn--search {
    height: 43px;
    background-color: $color-blue;
    width: 43px;
    position: relative;
    padding: 0;

    .c-btn__icon {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: -2px 0 0 0;
    }
}