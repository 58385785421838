/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
	display: inline-block; /* [1] */
	vertical-align: middle; /* [2] */
	font: inherit; /* [3] */
	text-align: center; /* [4] */
	margin: 0; /* [4] */
	cursor: pointer; /* [5] */
	text-decoration: none;
	padding: $spacing-unit-small $spacing-unit;
	transition: $global-transition;
	border-radius: $button-radius;
	border: 2px solid transparent;
	@include font-weight(bold);
	color: inherit;
}

	.c-btn__icon {
		display: inline-block;
		margin-right: $spacing-unit-tiny;
		max-height: 18px;
		max-width: 18px;		
		
		> svg {
			height: 18px;
			width: 18px;
			fill: currentColor;
			vertical-align: middle;
		}
	}

	.c-btn__icon--arrow {
		margin-left: $spacing-unit-small;
		margin-right: 0;

		> svg {
			height: 10px;
			width: 10px;
		}		
	}	

	.c-btn__shorttext { 
		@include mq(desktop) {
			display: none;
		}
	 }

	.c-btn__longtext { 
		@include mq($until: desktop) {
			display: none;
		}
	 }	 


/* Style variants
   ========================================================================== */

.c-btn--primary {
	background-color: $color-btn-primary;
	
	&,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none; /* [4] */
		color: #fff;
	}
	&:hover,
	&:focus {
		background-color: $color-btn-primary-hover;
	}
}

.c-btn--secondary {
	background-color: $color-btn-secondary;

	&,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		color: $color-blue;
	}
	&:hover,
	&:focus {
		background-color: $color-btn-secondary-hover;		
	}
}

.c-btn--success {
	background-color: $color-btn-success;
	
	&,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		color: #fff;
	}
	&:hover,
	&:focus {
		background-color: $color-btn-success-hover;		
	}
}

.c-btn--warning {
	background-color: $color-btn-warning;
	
	&,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		color: #fff;
	}
	&:hover,
	&:focus {
		background-color: $color-btn-warning-hover;		
	}
}

.c-btn--error {
	background-color: $color-btn-error;
	
	&,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		color: #fff;
	}
	&:hover,
	&:focus {
		background-color: $color-btn-error-hover;		
	}
}

.c-btn--contrast {
	background-color: $color-btn-contrast;
	
	&,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		color: $color-btn-primary;
	}
}

.c-btn--link {
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-left: 0;
}

.c-btn--circle {
	height: 46px;
	width: 46px;
	position: relative;
	padding: 0;

	.c-btn__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);	
		margin: 0;
		> svg {
			display: block;
		}
	}	
}


/* Ghost buttons
   ========================================================================== */

/**
 * Ghost buttons have see-through backgrounds and are bordered.
 */

 $btn-ghost-border-width: 2px;

.c-btn--ghost {
 	border: $btn-ghost-border-width solid currentColor;
	 
 	&,
 	&:hover,
 	&:active,
 	&:focus {
		 background: none;
	}
	 
 	&.c-btn--small {
 		padding: ($spacing-unit-tiny - $btn-ghost-border-width) ($spacing-unit-small - $btn-ghost-border-width);
	}
	 
 	&.c-btn--large {
 		padding: ($spacing-unit - $btn-ghost-border-width) ($spacing-unit-large - $btn-ghost-border-width);
	}
	 
 	&.c-btn--primary {
		 color: $color-btn-primary;
		 
 		&:hover,
 		&:focus {
 			color: $color-btn-primary-hover;
 		}
	}

 	&.c-btn--success {
		color: $color-btn-success;	

		&:hover,
		&:focus {
			color: $color-btn-success-hover;
		}		
	}
	
	&.c-btn--warning {
		color: $color-btn-warning;

		&:hover,
		&:focus {
			color: $color-btn-warning-hover;
		}
	}
	
	&.c-btn--error {
		color: $color-btn-error;

		&:hover,
		&:focus {
			color: $color-btn-error-hover;
		}
	}	

	&.c-btn--contrast {
		color: $color-btn-contrast;
	}	
}


/* Size variants
   ========================================================================== */

.c-btn--small {
	padding: $spacing-unit-tiny $spacing-unit-small;
}

.c-btn--large {
	padding: $spacing-unit $spacing-unit-large;
}