/*------------------------------------*\
    #LIST BLOCK
\*------------------------------------*/

.c-list-block {
    list-style: none;
    width: 100%;
    margin-bottom: 0;
}

    .c-list-block__item {
        border-top: 1px solid $color-gray-light;
        padding-top: $spacing-unit; 
        padding-right: $spacing-unit; 
        padding-bottom: $spacing-unit; 
        padding-left: $spacing-unit;         
        
        @include mq(desktop) {
            padding-right: $spacing-unit-large; 
            padding-left: $spacing-unit-large; 
        }
    }

    .c-list-block__item--small {
        padding-top: $spacing-unit-small; 
        padding-bottom: $spacing-unit-small; 
    }        