/* ==========================================================================
   #CONTENT BLOCK
   ========================================================================== */

/*
*  Widely used throughout the site to apply padding around rich text content. 
*  The internal contents of this container should determine the vertical spacing and rythym.
*/

.o-content-block {
    padding-top: $spacing-unit;
    overflow: auto;
}

.o-content-block + .o-content-block {
    padding-top: 0;
}
  
  
  
  /* Size variants.
     ====================================== */