/*------------------------------------*\
    #REPOSITORY
\*------------------------------------*/

.c-repository {
    
}

    .c-repository__aside {
        position: relative;
        padding-top: $spacing-unit;
        padding-right: $spacing-unit;
        padding-left: $spacing-unit;
        background-color: transparent;
        width: 100%;

        &:before {
            position: absolute;
            content: '';
            background-color: $color-gray-light;
            top: 0;
            right: 0;
            bottom: 0;
            width: 10000px;
            z-index: -1;
        } 
    }

    .c-repository__content {}

    .c-repository__results {
        color: $color-blue;
        @include font-size(12px);
        @include font-weight(bold);
        margin-bottom: 0;
    }

    .c-repository__pager {
        text-align: center;

        @include mq(tablet) {
            text-align: right;
        }
    }    