/* ==========================================================================
   INUITCSS
   ========================================================================== */


// SETTINGS
@import "settings/settings.config";
$inuit-global-font-size:    14px;
$inuit-global-line-height:  20px;
@import "bower_components/inuitcss/settings/settings.core";
@import "settings/settings.global";
@import "settings/settings.colors";
@import "settings/settings.magnific-popup";





// TOOLS
@import "bower_components/inuitcss/tools/tools.font-size";
@import "bower_components/inuitcss/tools/tools.clearfix";
@import "bower_components/inuitcss/tools/tools.hidden";
$mq-breakpoints: (
    mobile:  320px,
    tablet:  740px,
    desktop: 980px,
    wide:    1380px
);
// $mq-show-breakpoints: (mobile, tablet, desktop, wide);
@import "bower_components/sass-mq/mq";

@import "tools/tools.aliases";
@import "tools/tools.font-weight";
@import "tools/tools.gradient";





// GENERIC
@import "bower_components/inuitcss/generic/generic.box-sizing";
@import "bower_components/inuitcss/generic/generic.normalize";
@import "bower_components/inuitcss/generic/generic.reset";
@import "bower_components/inuitcss/generic/generic.shared";





// ELEMENTS
@import "bower_components/inuitcss/elements/elements.page";
@import "elements/elements.page";
@import "bower_components/inuitcss/elements/elements.headings";
@import "elements/elements.headings";
@import "bower_components/inuitcss/elements/elements.images";
@import "bower_components/inuitcss/elements/elements.tables";





// OBJECTS
//
// Uncomment and add to this section as necessary.
@import "bower_components/inuitcss/objects/objects.layout";
@import "bower_components/inuitcss/objects/objects.media";
@import "bower_components/inuitcss/objects/objects.flag";
@import "bower_components/inuitcss/objects/objects.list-bare";
@import "bower_components/inuitcss/objects/objects.list-inline";
@import "bower_components/inuitcss/objects/objects.box";
@import "objects/objects.box";
@import "bower_components/inuitcss/objects/objects.block";
@import "bower_components/inuitcss/objects/objects.ratio";
@import "bower_components/inuitcss/objects/objects.crop";
@import "bower_components/inuitcss/objects/objects.table";
@import "bower_components/inuitcss/objects/objects.pack";
$inuit-wrapper-width: 1260px;
@import "bower_components/inuitcss/objects/objects.wrapper";
@import "objects/objects.wrapper";
@import "objects/objects.content-block";





// VENDOR
@import "bower_components/magnific-popup/src/css/main";
@import "bower_components/owl.carousel/src/scss/owl.carousel";




// COMPONENTS
//
// Build and include your project’s UI Compoments here.
@import "components/components.article";
@import "components/components.accordion";
@import "components/components.bands";
@import "components/components.buttons";
@import "components/components.carousel";
@import "components/components.page-head";
@import "components/components.page-foot";
@import "components/components.forms";
@import "components/components.topnav";
@import "components/components.hamburger";
@import "components/components.subnav";
@import "components/components.dropdown";
@import "components/components.social";
@import "components/components.social-widgets";
@import "components/components.intro-block";
@import "components/components.cta";
@import "components/components.promo";
@import "components/components.numbered-list";
@import "components/components.unordered-list";
@import "components/components.status-banner";
@import "components/components.image-tile";
@import "components/components.enlarge";
@import "components/components.jumbo-block";
@import "components/components.list-block";
@import "components/components.resource";
@import "components/components.tag-list";
@import "components/components.search";
@import "components/components.repository";
@import "components/components.pagination";
@import "components/components.tabs";
@import "components/components.featured-tile";
@import "components/components.search-control";





// UTILITIES
@import "bower_components/inuitcss/utilities/utilities.clearfix";

$inuit-offsets: true;
$inuit-fractions: 1 2 3 4 5 6 12;
@import "bower_components/inuitcss/utilities/utilities.widths";
@import "bower_components/inuitcss/utilities/utilities.headings";
@import "bower_components/inuitcss/utilities/utilities.spacings";
$inuit-responsive-spacing-sizes: (
    "-none": 0,
    "-large": $spacing-unit-large
);
@import "bower_components/inuitcss/utilities/utilities.responsive-spacings";
@import "bower_components/inuitcss/utilities/utilities.print";
@import "bower_components/inuitcss/utilities/utilities.hide";
@import "utilities/utilities.headings";
@import "utilities/utilities.helpers";
@import "utilities/utilities.collapse";
@import "utilities/utilities.lead";