/*------------------------------------*\
    #ACCORDION
\*------------------------------------*/

.c-accordion {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;    
}

    .c-accordion__row {
        + .c-accordion__row {
            border-top: 1px solid rgba(255,255,255, 0.1);
        }
    }

    .c-accordion__item {
        padding-top: $spacing-unit;
        padding-bottom: $spacing-unit;
        cursor: pointer;
        
        + .c-accordion__item {
            border-top: 1px solid rgba(255,255,255, 0.5);
        }
    }

    .c-accordion__content {
        background-color: #fff;
    }

        .c-accordion__num {
            @include font-size(20px, 23px);
            @include font-weight(bold);
            font-family: $heading-font;

            @include mq(tablet) {
                @include font-size(23px);
            }
        }

        .c-accordion__title {
            @include font-size(23px, 23px);
            display: block;
            font-family: $heading-font;
            margin-bottom: 0;
            
            @include mq(tablet) {
                display: inline-block;
                vertical-align: middle;
                @include font-size(27px);
                margin-right: $spacing-unit-large;
            }            
        }

        .c-accordion__subtitle {
            @include font-size(12px);
            display: block;
            font-style: italic;
            margin-right: $spacing-unit-huge;
            
            @include mq(tablet) {
                display: inline-block;
                vertical-align: middle;
                @include font-size(14px);
                margin-right: 0;

                &:before {
                    content: '-';
                }
            }                   
        }

        .c-accordion__toggle {
            float: right;
            transition: $global-transition;
            
            > svg {
                width: 30px;
                height: 30px;
                display: block;
            }
            
            &[aria-expanded="true"] {
                transform: rotate(180deg);
            }
        }
        
        
/* Style variants
================================== */

.c-accordion--primary {
    background-color: #459ee9;

    .c-accordion__item {
        color: $color-yellow;
    }
    
    .c-accordion__title {
        color: $color-yellow;
    }

        .c-accordion__subtitle {
             color: white;
        }    
}

.c-accordion--secondary {
    @include gradient(#BF6D20, #A82760, horizontal);

    .c-accordion__num {
        color: #fff;
    }

    .c-accordion__title {
        color: #fff;
    }

        .c-accordion__subtitle {
             color: inherit;
        }      
}

.c-accordion--background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;    
}