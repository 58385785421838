/* ==========================================================================
   #SOCIAL ICONS
   ========================================================================== */

/**
 * Strip of social icons displayed inline 
 */

 .c-social {
     @include mq($from: tablet) {
         text-align: right;
     }
 }

    .c-social__item {

        + .c-social__item {
            margin-left: $spacing-unit-small;
        }
    }

        .c-social__btn {
            background-color: white;
            border-radius: 50%;
            width: 38px;
            height: 38px;
            position: relative;
            display: inline-block;

            svg {
                fill: $color-blue;
            }            
        }