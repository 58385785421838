/* ==========================================================================
   #LEAD
   ========================================================================== */

/**
 * Apply to paragraphs to give an emphysized style.
 */

.u-lead {
    @include font-size(15px);
    @include font-weight(bold);    
    color: $color-blue;
}
