/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Add custom support for responsive font sizes for heading utility classes.
 *
 *   <h2 class="u-h1--responsive"></h2>
 *
 */

.u-h1--responsive {
    @include font-size(20px, $important: true);

    @include mq(tablet) {
        @include font-size(32px, $important: true);
    }

    @include mq(desktop) {
        @include font-size($inuit-font-size-h1, $important: true);
    }
}
  