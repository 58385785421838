/* ==========================================================================
   #UNORDERED LIST
   ========================================================================== */

/**
 * Used for content editors to apply a custom list style to standard UL lists.
 *
 * Usage:
 * 
 * <ul class="c-unordered-list">
 *     <li>Number 1</li>
 *     <li>Number 2</li>
 *     <li>etc</li>
 * </ul>
 */

.c-unordered-list {
    list-style: none;
	padding-left: 0;
	margin-left: $spacing-unit-small;

    > li {
        margin-left: 0;
        margin-bottom: $spacing-unit-small;
        list-style: none;
        position: relative;
        
        &:before {
            content: '';
            display: inline-block;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            margin-right: $spacing-unit-small;
            background-color: $color-blue;
        }        
    }
}