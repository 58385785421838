/* ==========================================================================
   #NUMBERED LIST
   ========================================================================== */

/**
 * Used for content editors to apply a numbered list style
 * with highlighted numbers.
 *
 * Usage:
 * 
 * <ol class="c-numbered-list">
 *     <li>Number 1</li>
 *     <li>Number 2</li>
 *     <li>etc</li>
 * </ol>
 */

.c-numbered-list {
    list-style: none;
	counter-reset: li;
	margin-left: 0;
	padding-left :0;

    > li {
        margin-left: 0;
        margin-bottom: $spacing-unit-small;
        padding-left: $spacing-unit + 10px;
        list-style: none;
        position: relative;

        &:before {
            content: counter(li); /* Use the counter as content */
            counter-increment: li; /* Increment the counter by 1 */
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            background-color: $color-yellow;
            color: $color-blue;
            text-align: center;
            @include font-weight(bold);
        }
    }
}