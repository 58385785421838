/*------------------------------------*\
    #IMAGE TILE
\*------------------------------------*/

.c-image-tile {
    position: relative;
    display: block;
    color: $color-blue;
    text-decoration: none;
    text-align: center;
    padding-top: $spacing-unit-small;
    padding-right: $spacing-unit-small;
    padding-bottom: $spacing-unit-small;
    padding-left: $spacing-unit-small;
    border-left: 1px solid $color-gray-light;

    @include mq(tablet) {
        padding-top: $spacing-unit;
        padding-right: $spacing-unit;
        padding-bottom: $spacing-unit;
        padding-left: $spacing-unit;        
    }

    &:hover,
    &:focus,
    &.is-active {
        background-color: $color-blue;
        color: #fff;

        .c-image-tile__icon { display: none; }
        .c-image-tile__icon--hover { display: block; }
    }
}

    .c-image-tile__icon { 
        display: block;
        
        > svg {
            width: 40px;
            height: 40px;
        }

        @include mq(tablet) {
            > svg {
                width: 80px;
                height: 80px;
            }
        }
    }

    .c-image-tile__icon--hover { display: none; }

    .c-image-tile__img {
        display: block;
        margin-bottom: $spacing-unit-tiny;
    }

    .c-image-tile__label {
        display: block;
        text-transform: uppercase;
        font-family: $heading-font;
        @include font-size(11px);
        
        @include mq(tablet) {
            font-size: inherit;
        }
    }


/* Style variants
================================== */