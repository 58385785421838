/*------------------------------------*\
    #ARTICLE
\*------------------------------------*/

$article-border-width:  3px;
$article-border-width-large:  5px;

.c-article {
    position: relative;
    
    &:before {
        content: '';
        background-color: $color-blue;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: $article-border-width;
        margin-left: -($spacing-unit-small + $article-border-width);
    }

    @include mq(tablet) {
        margin-left: $spacing-unit;
        margin-right: $spacing-unit;

        &:before {
            width: $article-border-width-large;
            margin-left: -($spacing-unit + $article-border-width-large);
        }        
    }

    @include mq(desktop) {
        margin-left: $spacing-unit-large;
        margin-right: $spacing-unit-large;

        &:before {
            margin-left: -($spacing-unit-large + $article-border-width-large);
        }        
    }    
}

    .c-article__date {
        color: inherit;
        @include font-weight(bold);
        @include font-size(11px);
        margin-bottom: $spacing-unit-small;

        @include mq(tablet) {
            text-align: right;
            @include font-size(12px);
        }
    }
    
    .c-article__title {
        @include font-weight(bold);
        @include font-size(15px);
        margin-bottom: $spacing-unit-small;
        
        @include mq(tablet) {
            @include font-size(16px);
        }
    }

        .c-article__title-link {
            text-decoration: none;
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }

    .c-article__body {
        > :last-child {
            margin-bottom: 0;
        }        
    }

        .c-article__intro {
            @include mq(tablet) {
                margin-bottom: $spacing-unit;
            }
        }

        .c-article__content {
            margin-bottom: $spacing-unit;

            > :last-child {
                margin-bottom: 0;
            }                 
            
            @include mq(tablet) {
                display: block;
            }        
        }

        .c-article__images {
            > :last-child {
                margin-bottom: 0;
            }   
        }

            .c-article__img {
                margin-bottom: $spacing-unit;

                @include mq(tablet) {
                    margin-bottom: 0;
                }
            }
        
        .c-article__cta {
            display: none;

            @include mq(tablet) {
                display: block;
            }        
        }


/* Style variants
================================== */

.c-article--warning {

    &:before {
        background-color: $color-warning;
    }

    .c-article__date {
        color: $color-warning;
    }    
}

.c-article--error {
    
        &:before {
            background-color: $color-error;
        }
    
        .c-article__date {
            color: $color-error;
        }    
    }
