/* ==========================================================================
   #CALL TO ACTION
   ========================================================================== */

.c-cta {
    @include font-size(15px);
}

    .c-cta__btn {
        padding-left: $spacing-unit;
        padding-right: $spacing-unit;
        width: 100%;
        text-align: left;
    }


/* Style variants
================================== */

.c-cta--promo {
    color: $color-blue;
    @include gradient(#FEE300, #E59437, horizontal);
}

.c-cta--dark {
    color: white;
    background-color: $color-blue-dark;

    .c-cta__btn {
        @include font-size(18px);
        @include font-weight(bold);
        font-family: $heading-font;
        text-transform: uppercase;
    }
}