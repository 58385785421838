/* ==========================================================================
   #PROMO
   ========================================================================== */

.c-promo {
    @include font-size(18px);
    display: block;  

    @include mq(tablet) {
        display: table;
    }    
}

    .c-promo__img {
        margin-bottom: $spacing-unit;
        display: block;

        @include mq(tablet) {
            padding-right: $spacing-unit;
            margin-bottom: 0;
            display: table-cell;
            vertical-align: middle;
        }
    }
    
        .c-promo__grabber {
            @include font-size(23px, 23px);
            @include font-weight(bold);
            display: inline-block;
            font-family: $heading-font;
            text-transform: uppercase;
            border: 3px solid $color-gray;
            padding-top: $spacing-unit-small;
            padding-right: $spacing-unit;
            padding-bottom: $spacing-unit-small;
            padding-left: $spacing-unit-small;
            width: 150px;
        }

            .c-promo__grabber-icon {
                display: inline-block;
                margin-right: $spacing-unit-tiny;
                max-height: 18px;
                max-width: 18px;		
                
                > svg {
                    height: 18px;
                    width: 18px;
                    fill: currentColor;
                    vertical-align: middle;
                }
            }    

    .c-promo__body {
        display: block;
        @include font-size(15px);
        @include font-weight(bold);
        
        &,
        > :last-child {
            margin-bottom: 0;
        }        
        
        @include mq(tablet) {
            overflow: hidden;
            display: table-cell;
            vertical-align: middle;
        }        

        @include mq(desktop) {
            @include font-size(18px);
        }
    }