/* ==========================================================================
   #SOCIAL ICONS
   ========================================================================== */

/**
 * Strip of social icons displayed inline 
 */

.c-social-widget-container {
    height: 642px;
}

.c-social-widget-container-header,
c-social-widget-container-body {
    padding: 5px;
}

.c-social-widget-container-body {
    border: 2px solid $color-blue;
    border-top: none;
}

.c-social-widget-container .facebook-header {
    background-color: #3B5998;
    border: 2px solid #3B5998;
}

.c-social-widget-container .twitter-header {
    background-color: #55acee;
    border: 2px solid #55acee;
}

.c-social-widget-container .c-social-widget-container-header h3,
.c-social-widget-container .c-social-widget-container-header h5 {
    color: #fff;
}


.fb-page,
.fb-page span,
.fb-page span iframe[style] {
    max-width: 100% !important;
}