/* ==========================================================================
   #PAGE HEAD
   ========================================================================== */

.c-page-head {
    position: relative;
    border-bottom: 1px solid $color-gray-light;   

    &:before {
        display: block;
        content: '';
        background-image: url('/assets/build/images/tape.gif');
        background-repeat: repeat-x;
        height: 7px;
        width: 100%;
    }
}

    .c-page-head__home-link {
        display: block;
        float: left;
        padding-top: $spacing-unit-small;
        padding-bottom: $spacing-unit-small;        
    }

        .c-page-head__logo {
            height: 40px;
            width: 152px;

            @include mq(tablet) {
                height: 54px;
                width: 205px;
            }
        }    

    .c-page-head__nav {
        display: block;
        margin-left: -$spacing-unit; 
        margin-right: -$spacing-unit; 
        clear: both;

        @include mq(desktop) {
            float: right;   
            width: auto;
            clear: none;
            padding-top: 16px;
        }
    }

    .c-page-head__burger {
        float: right;
        margin-left: $spacing-unit;
        padding-top: 10px;

        @include mq(tablet) {
            padding-top: 15px;
        }
    }

