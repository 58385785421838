/* ==========================================================================
   #BOX
   ========================================================================== */

  
  
  
  /* Size variants.
     ========================================================================== */

.o-box--responsive {
    padding: $spacing-unit;

    @include mq(tablet) {
        padding: $spacing-unit-large;
    }

    @include mq(desktop) {
        padding: $spacing-unit-huge;
    }
}