/* ==========================================================================
   #SUB NAV
   ========================================================================== */

/**
 * Hidden by default and shown when user has click the burger icon in the header
 */

.c-subnav {
    background: #fff;
}

    .c-subnav__list {
        list-style: none;
        margin-left: 0;
        margin-bottom: 0;
    }

        .c-subnav__item {
            margin-left: 0;
            text-align: right;
        }

            .c-subnav__link {
                display: block;
                width: 100%;
                color: $color-blue;
                text-decoration: none;
                @include font-weight(bold);
                text-transform: uppercase;
                padding-top: $spacing-unit-small;
                padding-right: $spacing-unit;        
                padding-bottom: $spacing-unit-small;
                padding-left: $spacing-unit;

                &:hover {
                    background-color: $color-gray-light;
                }
            }

    .c-subnav__sublist {
        list-style: none;
        padding-left: $spacing-unit;
        margin-left: 0;
        margin-bottom: 0;
        background-color: $color-blue;

        .c-subnav__link {
            color: white;
        }        
    }    