/* ==========================================================================
   #COLLAPSE
   ========================================================================== */

/**
 * Classes used to control the collapse.js opening and closing states
 */

.collapse {
    display: none;
}

.collapse.show {
    display: block;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}  