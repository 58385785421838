/*------------------------------------*\
    #ENLARGE
\*------------------------------------*/

.c-enlarge {
    position: relative;
    display: block;
    overflow: hidden;

    &:hover {
        .c-enlarge__expand {
            transform: scale(1.2);
        }
    }
}

    .c-enlarge__expand {
        cursor: pointer;
        padding: $spacing-unit-tiny;
        display: block;
        position: absolute;
        top: auto;
        bottom: $spacing-unit-small;
        right: $spacing-unit-small;
        background: rgba(0,0,0,0.3);
        transition: $global-transition;
        
        &:after {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            background-image: url('/assets/build/images/icon-fullscreen.svg');
            background-size: 15px 15px;   
        }
    }


/* Style variants
================================== */

.c-enlarge--video {

    &:hover {
        .c-enlarge__expand {
            transform: none;
        }
    }    

    .c-enlarge__expand {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80px;
            height: 80px;
            background-image: url('/assets/build/images/icon-play.svg');
            background-size: 80px 80px;   
            opacity: 0.9;
            transform: translate(-50%, -50%);
        }
    }    
}