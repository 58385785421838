/* ==========================================================================
   #DROPDOWN
   ========================================================================== */

/**
 * Toggle contextual overlays for displaying lists of links and more.
 */

.dropdown {
    
    @include mq(tablet) {
        position: relative;
    }
}

.dropdown-toggle {

    &:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-left: 0.3em solid transparent;
    }    
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 10rem;
    color: #fff;
    text-align: left;
    list-style: none;
    background-color: $color-blue;
    background-clip: padding-box;
    width: 100%;

    @include mq(tablet) {
        float: left;
        z-index: 1000;
    }
}

.dropdown-item {
    display: block;
    width: 100%;
    padding-top: $spacing-unit-small;
    padding-right: $spacing-unit;        
    padding-bottom: $spacing-unit-small;
    padding-left: $spacing-unit;
    clear: both;
    font-weight: normal;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background: none;
    border: 0;
    text-decoration: none;
    font-weight: bold;

    &:focus,
    &:hover {
        color: #fff;
        text-decoration: none;
        background-color: $color-blue-light;
    }

    &.active,
    &:active {
        color: #fff;
        text-decoration: none;
        background-color: $color-blue-light;
    }  

}

.dropdown-menu.show {
    display: block;
}