/*------------------------------------*\
    #TAG LIST
\*------------------------------------*/

.c-tag-list {
    margin-bottom: $spacing-unit-small;
}

    .c-tag-list__item {
        display: inline-block;
    }

    .c-tag-list__btn {
        margin-bottom: $spacing-unit-small;

        &:hover {
            color: $color-blue-light;
        }

        &:active,
        &.is-active {
            background-color: $color-btn-primary;
            color: white;
        }
    }    