/*------------------------------------*\
    #RESOURCE
\*------------------------------------*/

.c-resource {
    
}

    .c-resource__main {
        
    }    

        .c-resource__title {
            @include font-size(14px);
            @include font-weight(bold);
            margin-bottom: 0;
            color: $color-gray;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            @include mq(tablet) {
                @include font-size(16px);
            }
        }        

    .c-resource__tags {
        @include font-weight(bold);
        text-align: right;
        width: 140px;
        color: $color-blue;
        display: none;

        @include mq(tablet) {
            display: table-cell;
        }        
    }  
    
    .c-resource__date {
        @include font-size(12px);
        @include font-weight(bold);
        color: $color-gray-mid;
        text-align: right;
        width: 140px;
    }      