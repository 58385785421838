/* ==========================================================================
   #HELPERS
   ========================================================================== */

/**
 * A series of helper classes to use arbitrarily. Only use a helper class if an
 * element/component doesn’t already have a class to which you could apply this
 * styling, e.g. if you need to float `.main-nav` left then add `float:left;` to
 * that ruleset as opposed to adding the `.float--left` class to the markup.
 *
 * A lot of these classes carry `!important` as you will always want them to win
 * out over other selectors.
 */


/**
 * Text alignment
 */
 .u-text-left     { text-align: left  !important; }
 .u-text-center   { text-align: center!important; }
 .u-text-right    { text-align: right !important; }