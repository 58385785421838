/* ==========================================================================
   #JUMBO BLOCK
   ========================================================================== */

.c-jumbo-block {
    display: block;   
    width: 100%;
    position: relative;
    background-color: $color-blue;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;    
    padding-top: $spacing-unit;
    padding-right: $spacing-unit;
    padding-bottom: $spacing-unit + 10px;
    padding-left: $spacing-unit;

    @include mq(tablet) {
        padding-top: $spacing-unit-large;
        padding-right: $spacing-unit-large;
        padding-bottom: $spacing-unit-large + 40px; // padding + button height
        padding-left: $spacing-unit-large;
    }    

    @include mq(wide) {
        padding-top: $spacing-unit-huge;
        padding-right: $spacing-unit-huge;
        padding-bottom: $spacing-unit-huge + 40px; // padding + button height
        padding-left: $spacing-unit-huge;
    }      
}

    .c-jumbo-block__title {
        display: block;   
        color: inherit;
        @include font-size(20px);
        
        @include mq(tablet) {
            @include font-size(32px);
        }

        @include mq(desktop) {
            @include font-size(35px);
            margin-bottom: $spacing-unit + 10px;
        }        
    }

    .c-jumbo-block__summary {
        display: none;
        
        @include mq(tablet) {
            display: block;   
        }
        @include mq(desktop) {
            margin-bottom: $spacing-unit + 10px;   
        }        
    }

    .c-jumbo-block__btn {
        display: none;

        @include mq(tablet) {
            display: inline-block;
            position: absolute;
            bottom: $spacing-unit-large;
            left: $spacing-unit-large;            
        }     
        
        @include mq(wide) {
            bottom: $spacing-unit-huge;
            left: $spacing-unit-huge;            
        }        
    }

    .c-jumbo-block__arrow {
        position: absolute;
        top: auto;
        left: $spacing-unit;
        bottom: $spacing-unit;

        > svg {
            height: 10px;
            width: 10px;            
            fill: #fff;
        }

        @include mq(tablet) {
            display: none;
        }
    }    

    .c-jumbo-block__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;        
        left: 0;
        width: 100%;

        @include mq(tablet) {
            display: none;
        }        
    }


/* Style variants
================================== */