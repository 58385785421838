/*------------------------------------*\
    #CAROUSEL
\*------------------------------------*/

.c-carousel__wrapper {
    min-height: 88px;
    
    @include mq(tablet) {
        min-height: 154px;
    }
}

.c-carousel {
    position: relative;

    .owl-carousel {
        display: none;
        
        &.owl-loaded {
            display: block;
        } 
    }

	.owl-nav {
        width: 100%;
        background-color: transparent;
        -webkit-tap-highlight-color: transparent;
        
		.disabled {
			opacity: 0.8;
			cursor: default;
		}
    }

    .owl-prev,
    .owl-next {
        color: black;
        background: rgb(255, 255, 255);
        display: inline-block;
        height: 100%;
        cursor: pointer;
        position: absolute;
        width: 40px;
        border: 1px solid $color-gray-light;
    
        &:hover {
            text-decoration: none;
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            transform: translate(-50%, -50%);
            background-color: white;
            border-radius: 50%;            
            opacity: 0.2;
        }
        
		&.disabled {
            display: none;
		}        
    } 
    
    .owl-prev {
        top: 0;
        left: 0;
        box-shadow: 10px 5px 10px rgba(0,0,0,0.1);
    }

    .owl-next {
        top: 0;
        right: 0;
        box-shadow: -10px 5px 10px rgba(0,0,0,0.1);        
    }     
 
}


/* Style variants
================================== */