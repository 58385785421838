.fulltextsearch_searchbox
{
    width: 60%;
}

.fulltextsearch_results
{
    margin-top: 40PX;
}

.fulltextsearch_error {
    margin-top: 40px;
    font-size: x-large;
}

.fulltextsearch_pagination_ul {
    list-style: none;
    margin-left: 0px;

    li {
        float: left;
        padding-right: 10px;
    }

}

.fulltextsearch_pagination:after {
    clear: both;
    content: ' ';
    display: block;
    margin-bottom: 20px;
}

.fulltextsearch_info
{
    clear:both;
    padding-top: 20px;
}

.fulltextsearch_thispage
{
    font-weight:bold;
}

.fulltextsearch_thispage {
    .c-pagination__link {
        font-weight: normal;
    }
}