/* ==========================================================================
   #SEARCH CONTROL
   ========================================================================== */

/**
 * Search component shown in the header 
 */

.c-search-control {
  text-align: right;
  opacity: 0;
  margin-right:-$spacing-unit;
  padding-top: $spacing-unit-small;
  padding-bottom: $spacing-unit-small;
  transition: ease-in all 0.3s;
  .collapsing &,
  .show & {
      opacity: 1;
      margin-right: 0;
  }
}

.c-search-control__field {
  display: inline-flex;
  align-items: center;
}

.c-search-control__icon {
  margin-right: $spacing-unit;
  > svg {
    width: 24px;
    height: 24px;
    display: block;
  }
}

.c-search-control__input {
  color: white;
  @include font-size(24px);
  background-color: transparent;
  border: 0;
  font-style: italic;
  font-family: $brand-font;
  width: 260px;
  outline: none;
}

.c-search-control__input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(white, .5);
}
.c-search-control__input::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(white, .5);
}
.c-search-control__input:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(white, .5);
}
.c-search-control__input:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(white, .5);
}

.c-search-control__submit {
    height: 38px;	
    width: 38px;
    margin-right: 15px;
    svg {
        color: $color-blue;
        width: 10px;
    }
}
