/*------------------------------------*\
    #FEATURED TILE
\*------------------------------------*/

.c-feature-tile {
    display: block;
    position: relative;
    margin-bottom: $spacing-unit;
    background-color: $color-gray-light;

    @include mq(tablet) {
        padding-bottom: 63px;
    }
}

    .c-feature-tile__img,
    .c-feature-tile__body {
        display: block;
    }

    .c-feature-tile__img {
        width: auto;
        height: auto;
        display: block;
        position: relative;
        padding-top: 65%;
        overflow: hidden;

        img {
            display: block;
            position: absolute;
            max-width: none;
            min-width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: -9999px;
            right: -9999px;
            margin: auto;
        }
    }

    .c-feature-tile__body {
        padding-top: $spacing-unit;
        padding-right: $spacing-unit;
        padding-left: $spacing-unit;
        padding-bottom: $spacing-unit;

        @include mq(tablet) {
            padding-bottom: 0;
        }
    }

        .c-feature-tile__title {
            margin-bottom: $spacing-unit;
            @include font-size(23px, 26px);
            color: $color-blue;
            
            > a {
                text-decoration: none;
                color: inherit;
            }
        }    

        .c-feature-tile__text {
            display: block;
        }

        .c-feature-tile__footer {

            @include mq(tablet) {
                padding: 0 20px 20px;
                margin-top: -63px;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
            }

        }