/* ==========================================================================
   #INTRO BLOCK
   ========================================================================== */

.c-intro-block {
    display: block;

    @include mq(tablet) {
        display: table;
    }    
}

    .c-intro-block__lead {
        display: block;
        margin-bottom: $spacing-unit;
        max-width: 400px;

        @include mq(tablet) {
            display: table-cell;
            vertical-align: top;
            padding-right: $spacing-unit-large;
        }           
    }

        .c-intro-block__title {
            color: $color-blue;
            
            @include mq(tablet) {
                border-right: 3px solid $color-yellow;
                padding-right: $spacing-unit-large;
            }
            @include mq(desktop) {
                padding-right: $spacing-unit-huge;
            }            
        }

    .c-intro-block__content {
        display: block;
        margin-bottom: 0;

        @include mq(tablet) {
            display: table-cell;
            vertical-align: middle;
        }                   
    }