/* ==========================================================================
   #STATUS BANNER
   ========================================================================== */

/**
 * Status banner that sits atop the screen, showing latest natural disaster warnings.
 */

.c-status-banner { 
    color: $color-status-banner-text;
    
    @include mq(desktop) {
        padding-top: $spacing-unit-large;
    }    
}

    .c-status-banner__inner {
        @include inuit-clearfix();
        position: relative; 
        display: block;        
    }

    .c-status-banner__img {
        margin-bottom: $spacing-unit-small;
        
        @include mq(tablet) {
            width: 40px;
            float: left;
            margin-right: $inuit-global-spacing-unit;        
            padding-top: $spacing-unit-small;
        }
    }

    .c-status-banner__body {
        overflow: hidden;
        display: block;
    
        &,
        > :last-child {
          margin-bottom: 0;
        }

    }

        .c-status-banner__date {
            @include font-weight(bold);
            @include font-size(11px);
            color: #fff;
            display: block;
            margin-bottom: $spacing-unit-small;

            @include mq(desktop) {
                @include font-size(12px);
                margin-bottom: 0;
            }            
        }

        .c-status-banner__title {
            @include font-weight(bold);
            @include font-size(16px);
            color: inherit;
            text-transform: none;
            font-family: $brand-font;

            @include mq(desktop) {
                @include font-size(24px);     
            }
        }        

        .c-status-banner__lead {
            @include font-weight(bold);
            @include font-size(13px, 18px);

            @include mq(desktop) {
                @include font-size(16px, 25px);
            }

            @include mq(wide) {
                padding-right: 20%;
            }            
        }

        .c-status-banner__link {
            float: right;
            display: none;
            margin-left: $spacing-unit; 

            @include mq(tablet) {
                display: block;
            }
        }        

        .c-status-banner__link-mobile {
            width: 100%;

            @include mq(tablet) {
                display: none;
            }            
        }         


/* Style variants
================================== */

.c-status-banner--ok {
    @include gradient($color-status-ok-gradient-start,  $color-status-ok-gradient-end, horizontal);
}

.c-status-banner--warning {
    @include gradient($color-status-warning-gradient-start,  $color-status-warning-gradient-end, horizontal);
}

.c-status-banner--error {
    @include gradient($color-status-error-gradient-start,  $color-status-error-gradient-end, horizontal);
}