//------------------------------------*\
//    #COLORS
//------------------------------------*/

$color-blue:        #015197;
$color-blue-light:  #00A7DF;
$color-blue-dark:   #0B1D32;
$color-yellow:      #FFE300;
$color-gray:        #09182A;
$color-gray-light:  #F8F6F8;
$color-gray-mid:    #979797;
$color-teal:        #50E2C2;
$color-success:     #3f990f;
$color-warning:     #F5A623;
$color-error:       #E72102;





// Page
$inuit-base-background-color:   #fff;
$inuit-base-text-color:         $color-gray;





// Links
$color-links:            $color-blue;
$color-links-hover:      $color-blue-light;





// Text
$color-code:            $color-teal;
$color-pre-background:  $color-gray;
$color-pre:             #fff;





// Buttons
$color-btn:                     $color-links;
$color-btn-primary:             $color-blue;
$color-btn-primary-hover:       darken($color-blue, 2.5%); 
$color-btn-secondary:           $color-gray-light; 
$color-btn-secondary-hover:     darken($color-gray-light, 2.5%);
$color-btn-success:             $color-success;
$color-btn-success-hover:       darken($color-success, 2.5%);
$color-btn-warning:             $color-warning;
$color-btn-warning-hover:       darken($color-warning, 2.5%);
$color-btn-error:               $color-error;
$color-btn-error-hover:         darken($color-error, 2.5%);
$color-btn-contrast:            #fff;





// Page head
$color-masthead:                #fff;
$color-page-head:               #fff;
$color-page-head-background:    #fff;





// Site nav
$color-site-nav:            $inuit-base-text-color;
$color-sub-menu-link:       $color-gray;
$color-sub-menu-link-hover: $color-links;





// Secondary nav
$color-nav-secondary:       $color-gray-light;
$color-nav-secondary-text:  $color-gray;





// Headings
$color-headings:    $color-blue;
$color-heading-sub: $color-gray;





// Status banner
$color-status-banner-text: #09182A;
$color-status-ok-gradient-start:   #87D006;
$color-status-ok-gradient-end:   #3F9F18;
$color-status-warning-gradient-start:   #FEC100;
$color-status-warning-gradient-end:   #E59437;
$color-status-error-gradient-start:   #FD5528;
$color-status-error-gradient-end:   #E62103;





// Tag List
$color-tag-list-btn: $color-blue;
$color-tag-list-btn-hover: $color-blue;
$color-tag-list-btn-active: $color-blue;