/* ==========================================================================
   #PAGE
   ========================================================================== */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');

html {
    font-family: $brand-font;
}

hr {
    border: none;
    border-bottom: 1px solid $color-gray-mid;
    margin-bottom: $spacing-unit;
}

a {
	transition: $global-transition;
	color: $color-links;
}