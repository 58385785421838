/* ==========================================================================
   #PAGE FOOT
   ========================================================================== */

.c-page-foot {
    @include gradient(#023a77, #34768e, horizontal);
    padding-top: $spacing-unit;
    color: white;

    &:after {
        display: block;
        content: '';
        background-image: url('/assets/build/images/tape.gif');
        background-repeat: repeat-x;
        height: 7px;
        width: 100%;
    }    
}

    .c-page-foot__head {
        border-bottom: 1px solid #2e6190;
        margin-bottom: $spacing-unit;
    }

        .c-page-foot__head-link {
            padding-left: 0px;
            padding-right: 0px;

            svg {
                fill: $color-yellow;
            }
        }

        .c-page-foot__disclaimer {
            @include mq($from: tablet) {
                text-align: right;
            }            
        }

    .c-page-foot__main {

    }

    .c-page-foot__nav {
        margin-bottom: $spacing-unit;
    }

        .c-page-foot__link {
            color: inherit;
            text-decoration: none;
            display: block;
            margin-bottom: $spacing-unit-small;
        }