/* ==========================================================================
   #HEADINGS
   ========================================================================== */

h1, h2, h3 {
  font-family: $heading-font;
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6 {
  color: $color-blue;
}