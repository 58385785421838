///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

$brand-font: 'Open Sans', sans-serif;
$heading-font: 'Roboto Condensed', sans-serif;

// Buttons
$button-radius: 120px;