/* ==========================================================================
   #PAGINATION
   ========================================================================== */

.c-pagination {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
    list-style: none;
}

.c-pagination__item {
    text-align: center;
}

.c-pagination__pager {
    display: block;

    > img {
        width: 12px;
        height: 12px;
        display: block;
    }
}

.c-pagination__link {
    @include font-weight(bold);
    @include font-size(16px);
    position: relative;
    display: block;
    text-decoration: none;
    color: $color-blue;
    background-color: white;
    padding: $spacing-unit-small;
    border-radius: 50%;

    &:active,
    .is-active & {
        color: white;
        background-color: $color-blue;
    }
}

.c-pagination__prev {
    padding-right: $spacing-unit;

    > img {
        transform: rotate(-180deg);
    }
}

.c-pagination__next {
    padding-left: $spacing-unit;
}

.c-repository__pager {

    .c-pagination__link {
        width: 40px;
        height: 40px;
    }
}
