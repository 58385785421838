/* ==========================================================================
   #HAMBURGER
   ========================================================================== */


// Settings
// ==================================================
$hamburger-padding-x: 15px;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 29px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $color-blue;
$hamburger-layer-border-radius: 0px;
$hamburger-hover-opacity: 0.7;
$hamburger-hover-transition-duration: 0.15s;
$hamburger-hover-transition-timing-function: linear;


// Styles
// ==================================================

.c-hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function; // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        opacity: $hamburger-hover-opacity;
    }

    &.is-active,
    &[aria-expanded=true] {

        .c-hamburger__inner {
            background-color: transparent;
            transform: rotateX(-180deg);

            &::before {
                transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
            }

            &::after {
                transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
            }
        }

    }     
}

.c-hamburger__box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    perspective: $hamburger-layer-width * 2;
    display: inline-block;
    position: relative;
}

.c-hamburger__inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }   
}

