/*------------------------------------*\
    #TABS
\*------------------------------------*/

.c-tabs {
    border-top: 1px solid $color-gray-light;
}

    .c-tabs__nav {
        display: none;
        
        @include mq(tablet) {
            margin: 0;
            padding: 0;
            display: flex;

            > :first-child {
                border-left: none;
            }
        }
    }    

        .c-tabs__item {
            flex-basis: 100%;
            display: inline-block;
            border-left: 1px solid $color-gray-light;
        }

        .c-tabs__link {
            display: block;
            width: 100%;
            height: 100%;
            text-decoration: none;
            font-family: $heading-font;	
            @include font-size(18px);
            @include font-weight(bold);          
            text-transform: uppercase;
            padding-top: $spacing-unit;
            padding-left: $spacing-unit-large;
            padding-bottom: $spacing-unit;
            padding-right: $spacing-unit-large;
            
            &:hover,
            &:focus {
                color: $color-links-hover;
            }
            
            &.active {
                background-color: transparent;
            }
        }    

    .c-tabs__pane {
        display: none;

        &.active {
            display: block;
        }

        @include mq(desktop) {
            > :first-child {
                padding-top: $spacing-unit;
            }
        }        
    }
    
    .c-tabs__dropdown {
        display: block;
        text-align: center;

        @include mq(tablet) {
            display: none;
        }
    }

        .c-tabs__select {
            width: 100%;
            color: $color-blue;
            @include font-size(12px);
            @include font-weight(bold);
            margin-bottom: $spacing-unit;
            border: 2px solid $color-blue;
            border-radius: $global-radius;
            padding-top: $spacing-unit-small; 
            padding-right: $spacing-unit; 
            padding-bottom: $spacing-unit-small; 
            padding-left: $spacing-unit; 
        }


/* Style variants
================================== */    

.c-tabs--primary {

    .c-tabs__nav {        

        > :first-child {

            .c-tabs__link.active {
                
                &:before {
                    width: 10000px;
                }                    
            } 

        }

        > :last-child {
            
            .c-tabs__link.active {
                
                &:before {
                    width: 10000px;
                    left: 0;
                    right: auto;
                }                    
            } 

        }        

    }

    .c-tabs__link {
        color: $color-links;

        &:hover,
        &:focus {
            color: $color-links-hover;
        }

        &.active {
            color: white;
            position: relative;
            
            &:before {
                position: absolute;
                content: '';
                width: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                background-color: $color-blue;
            }             
        }
    }

}