/*------------------------------------*\
  #FORMS
\*------------------------------------*/

.c-label {
    display: block;
  }
  
  .c-input-text {
    border: 1px solid $color-gray-mid;
    min-width: 25ch;
    outline: none;
    transition: border-color $global-transition;
    padding: 12px 6px;
    vertical-align: middle;
    border-radius: $global-radius;
  
    &:active,
    &:focus {
      border-color: $color-blue;
    }
  
  }