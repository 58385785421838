/*------------------------------------*\
    #BANDS
\*------------------------------------*/

.c-band {
    padding-top:    $spacing-unit;
    padding-bottom: $spacing-unit;

    > :last-child {
        margin-bottom: 0;
    }    
}


/* Style variants
================================== */

.c-band--small {
    padding-top: $spacing-unit-small;
    padding-bottom: $spacing-unit-small;
}

.c-band--large {
    padding-top: $spacing-unit-large;
    padding-bottom: $spacing-unit-large;
}

.c-band--huge {
    padding-top: $spacing-unit-huge;
    padding-bottom: $spacing-unit-huge;
}

.c-band--flush {
    padding-top: 0;
    padding-bottom: 0;
}

.c-band--responsive {
    padding-top:    $spacing-unit;
    padding-bottom: $spacing-unit;
    
    @include mq(tablet) {
        padding-top:    $spacing-unit + 10px;
        padding-bottom: $spacing-unit + 10px;
    }
    
    @include mq(desktop) {
        padding-top:    $spacing-unit-large;
        padding-bottom: $spacing-unit-large;
    }
}

.c-band--stacked {
    + .c-band--stacked {
        border-top: 1px solid $color-gray-light;
    }
}

.c-band--primary {
    @include gradient(#023a77, #34768e, horizontal);
}

.c-band--secondary {
    @include gradient(#FEE300, #E59437, horizontal);
}

.c-band--info {
    background-color: $color-teal;
}

.c-band--tint {
    background-color: $color-gray-light;
}

.c-band--background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
}